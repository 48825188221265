const UNIVERSAL = {
  // BASEURL: "https://www.laravel.crm.softvalley.net/",
  // BASEURL: "https://crm.mahbub.design/",
  // BASEURL: "https://dreamint.softvalley.net/",
  // BASEURL: "http://crm.new.sveducrm.com/",  //adept
  // BASEURL: "https://crm.softvalley.sveducrm.com/", //main
  BASEURL: "https://crm.ug.sveducrm.com/", //main
  //BASEURL: "https://api.crm.dreamstudyinternational.com/", //dream study
  ENCRYPTION_KEY: "#_2023_MARUF_SVCRM",
  JWT_SECRET: "",
};
export default UNIVERSAL;
